(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();import index_0App from "/opt/openstad-headless/apps/cms-server/node_modules/apostrophe/modules/@apostrophecms/util/ui/src/index.js";


import index_1App from "/opt/openstad-headless/apps/cms-server/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/src/index.js";


import index_2App from "/opt/openstad-headless/apps/cms-server/node_modules/apostrophe/modules/@apostrophecms/video-widget/ui/src/index.js";


import index_3App from "/opt/openstad-headless/apps/cms-server/modules/asset/ui/src/index.js";


import index_4App from "/opt/openstad-headless/apps/cms-server/modules/openstad-component-widget/ui/src/index.js";


import index_5App from "/opt/openstad-headless/apps/cms-server/modules/openstad-title-widget/ui/src/index.js";


import index_6App from "/opt/openstad-headless/apps/cms-server/modules/openstad-shareLinks-widget/ui/src/index.js";


import index_7App from "/opt/openstad-headless/apps/cms-server/modules/openstad-accordion-widget/ui/src/index.js";


import index_8App from "/opt/openstad-headless/apps/cms-server/modules/openstad-iconSection-widget/ui/src/index.js";


import index_9App from "/opt/openstad-headless/apps/cms-server/modules/openstad-button-widget/ui/src/index.js";


import index_10App from "/opt/openstad-headless/apps/cms-server/modules/openstad-rte-widget/ui/src/index.js";


import index_11App from "/opt/openstad-headless/apps/cms-server/modules/openstad-alertBox-widget/ui/src/index.js";


import index_12App from "/opt/openstad-headless/apps/cms-server/modules/openstad-carousel-widget/ui/src/index.js";



import index_0Stylesheet from "/opt/openstad-headless/apps/cms-server/node_modules/apostrophe/modules/@apostrophecms/image-widget/ui/src/index.scss";


import index_1Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/asset/ui/src/index.scss";


import index_2Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/default-page/ui/src/index.scss";


import index_3Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/openstad-section-widget/ui/src/index.scss";


import index_4Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/openstad-assets/ui/src/index.scss";


import index_5Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/openstad-title-widget/ui/src/index.scss";


import index_6Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/openstad-accordion-widget/ui/src/index.scss";


import index_7Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/openstad-rte-widget/ui/src/index.scss";


import index_8Stylesheet from "/opt/openstad-headless/apps/cms-server/modules/openstad-timeline-widget/ui/src/index.scss";index_0App();
index_1App();
index_2App();
index_3App();
index_4App();
index_5App();
index_6App();
index_7App();
index_8App();
index_9App();
index_10App();
index_11App();
index_12App();